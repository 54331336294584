import { Shoe } from "./interfaces";

export const shoes: Shoe[] = [
  {
    id:1,
    name:"timberland_TB0100730011",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0100730011/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0100730011/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0100730011/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:1,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:2,
    name:"timberland_TB010360214",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010360214/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010360214/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010360214/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:2,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:3,
    name:"timberland_TB010361713",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010361713/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010361713/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010361713/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:3,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:4,
    name:"timberland_TB056038001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB056038001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB056038001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB056038001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:4,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:5,
    name:"timberland_TB091566231",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB091566231/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB091566231/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB091566231/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:5,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:6,
    name:"timberland_TB095100214",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB095100214/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB095100214/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB095100214/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:6,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:7,
    name:"timberland_TB0A11TY001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A11TY001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A11TY001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A11TY001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:7,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:8,
    name:"timberland_TB0A2AGS231",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AGS231/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AGS231/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AGS231/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:8,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:9,
    name:"timberland_TB0A2AME001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AME001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AME001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2AME001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:9,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:10,
    name:"timberland_TB0A2FXF931",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2FXF931/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2FXF931/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2FXF931/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:10,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:11,
    name:"timberland_TB0A2G5C001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2G5C001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2G5C001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2G5C001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:11,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:12,
    name:"timberland_TB0A2KJP033",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KJP033/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KJP033/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KJP033/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:12,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:13,
    name:"timberland_TB0A2KMJ999",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMJ999/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMJ999/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMJ999/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:13,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:14,
    name:"timberland_TB0A2KMU231",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMU231/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMU231/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A2KMU231/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:14,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:15,
    name:"timberland_TB0A428J999",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A428J999/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A428J999/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A428J999/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:15,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:16,
    name:"timberland_TB0A43TU001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43TU001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43TU001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43TU001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:16,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:17,
    name:"timberland_TB0A43VU834",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43VU834/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43VU834/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43VU834/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:17,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:18,
    name:"timberland_TB0A43WU001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43WU001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43WU001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43WU001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:18,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:19,
    name:"timberland_TB0A43XJ231",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43XJ231/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43XJ231/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43XJ231/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:19,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:20,
    name:"timberland_TB0A43YH001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43YH001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43YH001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43YH001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:20,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:21,
    name:"timberland_TB0A43ZU001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43ZU001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43ZU001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A43ZU001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:21,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:22,
    name:"timberland_TB0A4425231",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A4425231/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A4425231/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A4425231/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:22,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:23,
    name:"timberland_TB0A5MCC999",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MCC999/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MCC999/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MCC999/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:23,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:24,
    name:"timberland_TB0A5MD7931",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MD7931/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MD7931/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5MD7931/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:24,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:25,
    name:"timberland_TB0A5QCZ001",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5QCZ001/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5QCZ001/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB0A5QCZ001/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:25,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:26,
    name:"timberland_a29vb",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a29vb/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a29vb/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a29vb/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:26,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:27,
    name:"timberland_a2qq7",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a2qq7/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a2qq7/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a2qq7/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:27,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:28,
    name:"timberland_a418u",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a418u/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a418u/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_a418u/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:28,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:29,
    name:"timberland_tb0a2km8cy311",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_tb0a2km8cy311/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_tb0a2km8cy311/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_tb0a2km8cy311/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:29,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:30,
    name:"timberland_0A2K2F-A5QY4",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5QY4/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5QY4/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5QY4/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:30,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:31,
    name:"timberland_0A2K2F-A5R28",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5R28/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5R28/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2K2F-A5R28/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:31,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:32,
    name:"timberland_0A2KJ3-A5M7K",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2KJ3-A5M7K/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2KJ3-A5M7K/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2KJ3-A5M7K/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:32,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:33,
    name:"timberland_0A2Q9B-A2QAS",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A2QAS/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A2QAS/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A2QAS/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:33,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:34,
    name:"timberland_0A2Q9B-A5PB7",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A5PB7/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A5PB7/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2Q9B-A5PB7/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:34,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:35,
    name:"timberland_0A2QG9-A2QRJ",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2QG9-A2QRJ/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2QG9-A2QRJ/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_0A2QG9-A2QRJ/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:35,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:36,
    name:"timberland_A2A38",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_A2A38/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_A2A38/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_A2A38/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:36,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:37,
    name:"timberland_JCM002-8658A",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JCM002-8658A/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JCM002-8658A/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JCM002-8658A/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:37,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:38,
    name:"timberland_JMC006FTB-A29YT",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB-A29YT/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB-A29YT/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB-A29YT/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:38,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:39,
    name:"timberland_JMC006FTB_A29RW",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB_A29RW/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB_A29RW/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_JMC006FTB_A29RW/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:39,
    date_created:1590499834,
    last_updated:1590499834
   },
   {
    id:40,
    name:"timberland_TB010061713",
    brand:"Timberland",
    image_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010061713/shoeIcon.png",
    assets_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010061713/offsets.json",
    model_url:"https://vykingsneakerkitnative.s3.amazonaws.com/boto3/upload/2022_09_20/timberland_TB010061713/model_r.glb",
    shop_button_url:"https://vyking.io",
    order:40,
    date_created:1590499834,
    last_updated:1590499834
   },
];
